import React, { useState, useEffect } from 'react'
import { useStaticQuery, graphql, Link } from 'gatsby'
import styled, { css } from 'styled-components'
import { useWindowScroll, useWindowSize, useMount } from 'react-use'

import { Layout, Section, Block, Slider } from '../components'
import { media } from '../styles/utils'
import {
    container,
    padding,
    colours,
    type,
    bgIcon,
    bgImage,
    buttonSmall,
    button,
} from '../styles/global'
import { parseACF, getImageCrop } from '../utils'
import { render } from 'react-dom'
import ProgressiveImage from 'react-progressive-image'
import { motion, transform } from 'framer-motion'
import { map } from 'lodash'

const About = (props) => {
    const { x, y } = useWindowScroll()
    const { width, height } = useWindowSize()

    const data = parseACF(useStaticQuery(query), 'allWordpressInfopages')

    // Our Story

    const renderSlider = () => {
        if (!data.ourstory_carousel) return

        return (
            <SliderWrapper>
                <Slider
                    slides={data.ourstory_carousel}
                    sliderStyles={sliderStyles}
                    hoverCursors
                />
            </SliderWrapper>
        )
    }

    const renderOurStory = () => {
        return (
            <OurStory id="our-story">
                {data.ourstory_heading && (
                    <Heading>{data.ourstory_heading}</Heading>
                )}

                {data.ourstory_text && (
                    <Text
                        dangerouslySetInnerHTML={{ __html: data.ourstory_text }}
                    />
                )}

                {renderSlider()}
            </OurStory>
        )
    }

    // Our Philosophy

    const renderOurPhilosophy = () => {
        return (
            <OurPhilosophy id="our-philosophy">
                <Wrap>
                    <Title>{data.ourphilosophy_heading}</Title>
                    <Text
                        dangerouslySetInnerHTML={{
                            __html: data.ourphilosophy_text,
                        }}
                    />
                </Wrap>
                <Wrap>
                    {data.ourphilosophy_image && (
                        <Image
                            key={data.ourphilosophy_image?.sizes?.medium2}
                            src={data.ourphilosophy_image?.sizes?.medium2}
                        >
                            {(src, loading) => {
                                return (
                                    <BGImage
                                        image={src}
                                        style={{ opacity: loading ? 0 : 1 }}
                                    />
                                )
                            }}
                        </Image>
                    )}
                </Wrap>
            </OurPhilosophy>
        )
    }

    // The Studio

    const renderTheStudio = () => {
        const items =
            data.studio_images &&
            data.studio_images.map((item, i) => {
                return (
                    <ImageItem key={i}>
                        <Image
                            key={item.image?.sizes?.medium2}
                            src={item.image?.sizes?.medium2}
                        >
                            {(src, loading) => {
                                return (
                                    <BGImage
                                        image={src}
                                        style={{ opacity: loading ? 0 : 1 }}
                                    />
                                )
                            }}
                        </Image>
                    </ImageItem>
                )
            })

        return (
            <TheStudio id="studio">
                <Title>{data.studio_heading}</Title>
                <Wrap>{items}</Wrap>
            </TheStudio>
        )
    }

    // Values

    const renderOurValues = () => {
        return (
            <OurValues>
                <Wrap>
                    <Image
                        key={data.values_image?.sizes?.medium2}
                        src={data.values_image?.sizes?.medium2}
                    >
                        {(src, loading) => {
                            return (
                                <BGImage
                                    image={src}
                                    style={{ opacity: loading ? 0 : 1 }}
                                />
                            )
                        }}
                    </Image>
                </Wrap>
                <Wrap>
                    <Title>{data.values_heading}</Title>
                    <Text
                        dangerouslySetInnerHTML={{ __html: data.values_text }}
                    />
                </Wrap>
            </OurValues>
        )
    }

    // How to Join

    const renderHowToJoin = () => {
        return (
            <HowToJoin>
                <Title>{data.join_heading}</Title>
                <Text dangerouslySetInnerHTML={{ __html: data.join_text }} />
            </HowToJoin>
        )
    }

    // Our Location

    const renderOurLocation = () => {
        if (!data || !data?.locations) return
        
        const items = data?.locations.map((item, i) => {
            return (
                <Item>
                    <Image
                        key={item?.location_image?.sizes?.medium2}
                        src={item?.location_image?.sizes?.medium2}
                    >
                        {(src, loading) => {
                            return (
                                <BGImage
                                    image={src}
                                    style={{ opacity: loading ? 0 : 1 }}
                                />
                            )
                        }}
                    </Image>

                    <Text class={'name'} dangerouslySetInnerHTML={{ __html: item?.location_name }} />
                    <Text class={'address'} dangerouslySetInnerHTML={{ __html: item?.location_address }} />
                </Item>
            )
        })

        return (
            <OurLocation>
                <Title
                    dangerouslySetInnerHTML={{ __html: data.location_heading }}
                />
                <Icon />
                
                <Items>
                    {items}
                </Items>
            </OurLocation>
        )
    }

    return (
        <Layout meta={data && data.seo}>
            <Container>
                {renderOurStory()}

                {renderOurPhilosophy()}

                {renderTheStudio()}

                {renderOurValues()}

                {renderHowToJoin()}

                <Section
                    layout={'join'}
                    display={data.joining_display}
                    classes={data.joining_classes}
                    title={data.joining_heading}
                    text={data.joining_text}
                    link={data.joining_link}
                    linkText={data.joining_link_text}
                />

                <Block layout={'big_button'} display />

                <Section
                    layout={'pricing'}
                    display={data.pricing_display}
                    title={data.pricing_heading}
                    image={data.pricing_image}
                    text={data.pricing_description}
                    useLink={data.pricing_use_button}
                    linkText={data.pricing_button_text}
                    link={data.pricing_button_link}
                />

                {renderOurLocation()}
            </Container>
        </Layout>
    )
}

// Shared

const Heading = styled.h1``
const Title = styled.h1``
const Text = styled.div``
const Wrap = styled.div``
const SliderWrapper = styled.div``
const Slide = styled.div``
const ImageItem = styled.div``
const Icon = styled.div``

const Items = styled.div``
const Item = styled.div``

// Utlity

const Image = styled(ProgressiveImage)`
    overflow: hidden;
`
const BGImage = styled.div`
    background-image: url(${(props) => props.image});
    ${bgImage};
    transition: opacity 0.45s ease;
`

// Layout

const Container = styled.div`
    ${container}
    ${padding}
	flex-direction: column;
    Margin-top: 200px;
`

// Slider

const sliderStyles = css`
    overflow: visible;
    height: 60vw;

    ${media.phone`
		height: 256px;
	`}

    .slide {
        background-color: ${colours.red};
    }

    .image-wrapper {
        width: 100%;

        img {
            width: 100%;
            height: 100%;
            // min-height: 25vw;
            object-fit: cover;
            object-position: center;

            ${media.phone`
				height: 256px;
			`}
        }
    }
`

const OurStory = styled.div`
    margin-bottom: 150px;

    ${media.tablet`
		margin-bottom: 70px;
	`}

    ${Heading} {
        ${type.heading1};
        padding-bottom: 32px;

        ${media.tablet`
			padding-bottom: 20px;
		`}
    }

    ${Text} {
        ${type.body};
        max-width: 80%;

        ${media.tablet`
			max-width: 100%;	
		`}
    }

    .swiper-container {
        .slider-next {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            z-index: 100;

            &:focus {
                outline: none;
            }
        }
    }

    ${Slide} {
        ${BGImage} {
            width: 100%;
			padding-top: calc(591.44 / 1127.34 * 100%);
            // min-height: 840px;

            // ${media.tablet`
			// 	min-height: 420px;	
			// `}
        }
    }
`

const PrevNav = styled.div`
    position: absolute;
    width: 50%;
    height: 100%;
    top: 0;
    left: 0;
`
const NextNav = styled.div`
    position: absolute;
    width: 50px;
    height: 100%;
    top: 0;
    right: 0;
`

// Our Philosophy

const OurPhilosophy = styled.div`
    margin-bottom: 150px;
    display: flex;
    justify-content: space-between;

    ${media.tablet`
		flex-direction: column;	
		margin-bottom: 70px;
	`}

    ${Wrap} {
        flex-basis: calc(50% - 20px);

        ${media.tablet`
			flex-basis: 100%;	
		`}

        ${Title} {
            ${type.heading1};
            padding-bottom: 32px;

            ${media.tablet`
				padding-bottom: 20px;	
			`}
        }

        ${Text} {
            ${type.body};
        }

        ${BGImage} {
            ${bgIcon};
            height: 100%;

            ${media.tablet`
                min-height: 50vw;
			`}
        }
    }
`

// The Studio

const TheStudio = styled.div`
    margin-bottom: 150px;

    ${media.tablet`
		margin-bottom: 70px;
	`}

    ${Title} {
        ${type.heading1};
        padding-bottom: 32px;

        ${media.tablet`
			padding-bottom: 20px;	
		`}
    }

    ${Wrap} {
        display: flex;
        flex-wrap: wrap;
        overflow: hidden;
        justify-content: center;

        ${ImageItem} {
            padding-bottom: 72px;

            ${media.tablet`
				padding-bottom: 20px;
			`}

            &:nth-child(1) {
                width: 100%;

                ${BGImage} {
                    max-width: 913px;
                    min-height: 643px;
                    margin: 0 auto 0 30%;
                    ${bgIcon};

                    ${media.tablet`
                        min-height: 50vw;
					`}
                }
            }

            &:nth-child(2) {
                width: 45%;

                ${BGImage} {
                    max-width: 540px;
                    min-height: 670px;
                    ${bgIcon};

                    ${media.tablet`
                        min-height: 50vw;
					`}
                }
            }

            &:nth-child(3) {
                width: 45%;
                padding-top: 160px;

                ${media.tablet`
					width: 50%;
					padding-left: 20px;	
					padding-top: 60px;
				`}

                ${BGImage} {
                    max-width: 540px;
                    min-height: 670px;
                    border-radius: 100%;

                    ${media.tablet`
						min-height: 50vw;
					`}
                }
            }
        }
    }
`

// Our Values

const OurValues = styled.div`
    margin-bottom: 150px;
    display: flex;
    justify-content: space-between;

    ${media.tablet`
		flex-direction: column-reverse;	
		margin-bottom: 70px;
	`}

    ${Wrap} {
        flex-basis: calc(50% - 20px);

        ${media.tablet`
			flex-basis: 100%;
		`}

        ${Title} {
            ${type.heading1};
            padding-bottom: 32px;

            ${media.tablet`
				padding-bottom: 20px;	
			`}

            ${media.tablet`
				padding-bottom: 20px;	
			`}
        }

        ${Text} {
            ${type.body};
        }

        ${BGImage} {
            ${bgIcon};
            height: 100%;

            ${media.tablet`
				min-height: 50vw;
				// min-height: 400px;
			`}
        }
    }
`

// How to Join

const HowToJoin = styled.div`
    ${Title} {
        ${type.heading1};
        padding-bottom: 32px;

        ${media.tablet`
			padding-bottom: 20px;	
		`}
    }

    ${Text} {
        ${type.body};
        max-width: 80%;

        ${media.tablet`
			max-width: 100%;	
		`}
    }
`

// Our Location

const OurLocation = styled.div`
    min-height: 850px;
    background: linear-gradient(
        180deg,
        #f6a73b -18.99%,
        rgba(246, 167, 59, 0) 100%
    );
    margin: 0 -30px;

    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 32px;
    box-sizing: border-box;

    transition: 0.6s cubic-bezier(0.25, 0.46, 0.45, 0.94);

    ${media.tablet`
		min-height: 500px;	
		margin: 0 -16px;
	`}

    ${Title} {
        ${type.heading1};
        text-align: center;
        padding-bottom: 32px;

        ${media.tablet`
			padding-bottom: 0;
		`}
    }

    ${Icon} {
        ${bgIcon};
        width: 20px;
        height: 40px;
        background-image: url(${require('../assets/svg/icon-pin.svg')});
        margin-bottom: 40px;

        ${media.tablet`
			height: 60px;	
		`}
    }

    ${Items}{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;

        ${media.tablet`
			flex-direction: column;
            padding: 0 40px;
		`}

        ${Item}{
            width: calc(50% - 20px);
            display: flex;
            flex-direction: column;
            align-items: center;

             ${media.tablet`
                width: 100%;
                margin-bottom: 40px;
             `}

            &:first-child {
                ${BGImage} {
                    transform: rotate(-3deg);
                }
            }

            &:nth-child(2) {
                ${BGImage} {
                    transform: rotate(3deg);
                }
            }

            ${BGImage} {
                ${bgIcon};
                max-width: 494px;
                max-height: 353px;
                width: 100vw;
                height: 100vw;
                margin-bottom: 20px;

                &:hover {
                    transform: rotate(0deg);
                }

                ${media.tablet`
                    max-width: 344px;
                    max-height: 223px;
                `}
            }

            ${Text}{
                text-align: center;
            }
        }
    }
`

// Data

export const query = graphql`
    query {
        allWordpressInfopages(filter: { slug: { eq: "about" } }) {
            nodes {
                acf_json
            }
        }
    }
`

export default About
